<template>
  <div>
    <div class="block banner bg">
      <div class="container">
        <div class="box">
          <h1>{{$t("dynamicAcceleration")}}</h1>
          <h2>{{$t('solution71')}}</h2>
          <p class="sm">{{$t('solution72')}}</p>
          <el-button type="primary">{{$t('getPlan')}}</el-button>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <h2>
          {{$t('solution73')}}
        </h2>
        <ul class="list1">
          <li class="icon25">
            <h5>{{$t('solution74')}}</h5>
            <p>{{$t('solution75')}}</p>
          </li>
          <li class="icon24">
            <h5>{{$t('solution76')}}</h5>
            <p>{{$t('solution77')}}</p>
          </li>
          <li class="icon27">
            <h5>{{$t('solution78')}}</h5>
            <p>{{$t('solution79')}}</p>
          </li>
          <li class="icon32">
            <h5>WebSocket</h5>
            <p>{{$t('solution80')}}</p>
          </li>
          <li class="icon28">
            <h5>{{$t('product134')}}</h5>
            <p>{{$t('solution81')}}</p>
          </li>
          <li class="icon29">
            <h5>{{$t('solution82')}}</h5>
            <p>{{$t('solution83')}}</p>
          </li>
          <li class="icon56">
            <h5>{{$t('solution84')}}</h5>
            <p>{{$t('solution85')}}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="block gray">
      <div class="container">
        <ul class="product-list">
          <li>
            <div class="img"><img src="@/assets/images/image4.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution86')}}</h4>
              <p>
                {{$t('solution87')}}<br/>
                •	{{$t('solution88')}}<br/>
                •	{{$t('solution89')}}<br/>
                •	{{$t('solution90')}}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <ul class="product-list">
          <li class="left">
            <div class="img"><img src="@/assets/images/image15.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution91')}}</h4>
              <p>{{$t('solution92')}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block gray">
      <div class="container">
        <ul class="product-list">
          <li>
            <div class="img"><img src="@/assets/images/image26.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution93')}}</h4>
              <p>{{$t('solution94')}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <ul class="product-list">
          <li class="left">
            <div class="img"><img src="@/assets/images/image28.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution95')}}</h4>
              <p>{{$t('solution96')}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block gray">
      <div class="container">
        <ul class="product-list">
          <li>
            <div class="img"><img src="@/assets/images/image27.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution97')}}</h4>
              <p>{{$t('solution98')}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <ul class="product-list">
          <li class="left">
            <div class="img"><img src="@/assets/images/image13.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution99')}}</h4>
              <p>{{$t('solution100')}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block gray">
      <div class="container">
        <h2>{{$t('solution101')}}</h2>
        <p>{{$t('solution102')}}</p>
        <div class="ac" style="margin-bottom: 1.3rem"><img src="@/assets/images/image29.svg" /></div>
        <PlanBox />
      </div>
    </div>
  </div>
</template>
<script>
  import PlanBox from '@/components/PlanBox.vue'

export default {
  name: "SolutionDynamic",
  components: {
    PlanBox
  },
  mounted() {
  },
  data() {
    return {
    }
  },
  metaInfo() {
    return {
      title: '「BoxCDN」'+this.$t('dynamicAcceleration')+'-'+this.$t('home80')
    }
  }
}
</script>
<style lang="scss" scoped>
  .banner {
    background-image: url('~@/assets/images/banner2.jpg');
  }
</style>
